import {ReactMic} from 'react-mic';
import WaveSurfer from 'wavesurfer.js';
import '../Ques/ques.scss';
import AudioRecorder from '../../components/MicRecord';
import Mic from '../../components/MicRecord';
import WaveBar from '../../components/waves/waves';
import { useState } from 'react';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Link } from 'react-router-dom';


const Ques=()=> {
  const [answers, setAnswers] = useState({
    question1: '',
    question2: ''
  });

  const diffToast = () =>{
    toast.success("Submitted Successfully",{
      position:"top-center"
    });
  }

  const errToast = () =>{
    toast.error("Submitted Unsuccessful",{
      position:"top-center"
    });
  }
  
  const [isVisible, setIsVisible] = useState(false);
  const [isMicOption,setIsMicOption] = useState(false);

  const handleAnswerChange = (event) => {
    const { name, value } = event.target;
    setAnswers(prevAnswers => ({
      ...prevAnswers,
      [name]: value
    }));
  }

  const toggleVisibility = () => {
    setIsVisible(!isVisible )
    setIsMicOption(false);
  }

  const MicVisibility = () =>{
    setIsVisible(false);
    setIsMicOption( !isMicOption);
  }
    
  return (
    <div className='Question'>
      <div className='row1'>
       <h4 className='FirstTopic'>J Turner</h4>
       <h2 className='SecondTopic'>User Experience Survey</h2>
       <p className='Quesdesc'>This survery is to help the J Turner team 
understand how our user see our reviews data</p>
      </div>
      <div className='spaced'>

      </div>
      <div className='row2'>
        <div className='questions'>
            <h3>1.Rate your overall experience for Stonebridge Gardens?</h3>
            <div className='optionsGiven'>
            <input  type='radio' value="Amazing" name='question1'  onChange={handleAnswerChange}/>Amazing
            </div>
            <div className='option2'>
            <input type="radio" value="Good" name="question1"  onChange={handleAnswerChange} /> Good
            </div>
          <div className='option3'>
          <input type="radio" value="Average" name="question1"  onChange={handleAnswerChange} /> Average
          </div>
        <div className='option4'>
        <input type="radio" value="Poor" name="question1"  onChange={handleAnswerChange} /> Poor
        </div>
       <div className='option5'>
       <input type="radio" value="Extremely poor" name="question1"   onChange={handleAnswerChange}/> Extremely poor
       </div>
       

           

        </div>

        <div className='questions'>
            <h3>2.Length of residence in StoneBridge Gardens?</h3>
            <div className='optionsGiven'>
            <input type='radio' value="0-6 months" name='0-6 months'  onChange={handleAnswerChange}/>0-6 months
            </div>
            <div className='option2'>
            <input type="radio" value="6-12 months" name="6-12 months"  onChange={handleAnswerChange} /> 6-12 months
            </div>
            <div className='option3'>
            <input type="radio" value="1-3 yrs" name="1-3 yrs"  onChange={handleAnswerChange}/> 1-3 yrs
            </div>
       <div className='option4'>
       <input type="radio" value="3 yrs & above" name="3 yrs & above"  onChange={handleAnswerChange}/> 3 yrs & above
       </div>
       

        </div>


        <div className='questions'>
            <h3>3. Share a 2 min feedback about StoneBridge Gardens?</h3>
            <div className='TwoOptions'>
            <button onClick={toggleVisibility} className='TextShow'>Enter Text</button>
            <h3 className='give'>--OR--</h3>
           <button onClick={MicVisibility} className='VoiceOnly'>Record Voice</button>
            </div>

            { 
              isMicOption &&
              <Mic/>
            }
          
           {/* <WaveBar/> */}
           {
            isVisible &&  
            <input type='text' className='my-input'></input>
           }
          

        </div>
        <button className='conti' onClick={answers.question1 !='' || answers.question2 !=''?diffToast:errToast}>
        
          Submit
        </button>
      
      </div>
      <ToastContainer/>
    </div>
  )
}

export default Ques;
