import React from 'react';
import './waves.scss';

const WaveBars = () => {
  return(
 
 <div className='loadingbars'>
  <div className='bar'></div>
  <div className='bar'></div>
  <div className='bar'></div>
  <div className='bar'></div>
  <div className='bar'></div>
  <div className='bar'></div>
 
 </div>
  )
};

export default WaveBars;
