import React, { useState, useEffect } from "react";
import questionsData from "../../assets/questions.json";
import "./feeds.scss";

const DynamicForm = () => {
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [answers, setAnswers] = useState({});
  const [previousQuestions, setPreviousQuestions] = useState([]);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  useEffect(() => {
    setQuestions(questionsData.questions);
    setCurrentQuestion(questionsData.questions[0]);
  }, []);

  const handleAnswerChange = (event) => {
    setAnswers({
      ...answers,
      [currentQuestion.id]: event.target.value,
    });
  };

  const handleNextClick = () => {
    setPreviousQuestions([
      ...previousQuestions,
      { question: currentQuestion, answer: answers[currentQuestion.id] },
    ]);
    const nextQuestionId =
      currentQuestion.type === "radio"
        ? currentQuestion.options.find(
            (option) => option.value === answers[currentQuestion.id]
          ).nextQuestion
        : currentQuestion.nextQuestion;

    const nextQuestion = questions.find(
      (question) => question.id === nextQuestionId
    );

    // If the next question is undefined, we have reached the end of the questions array
    if (nextQuestion === undefined) {
      // Submit the answers here
      console.log("Submitting answers:", answers);
      setShowSuccessMessage(true);
    } else {
      setCurrentQuestion(nextQuestion);
    }

    // Clear answer for the current question
    setAnswers({
      ...answers,
      [currentQuestion.id]: "",
    });
  };

  return (
    <div className="dynamic-form-container">
      <div className="previous-questions-container">
        {previousQuestions.map((previousQuestion, index) => (
          <div key={index}>
            <h2>{previousQuestion.question.text}</h2>
            {previousQuestion.question.type === "radio" && (
              <div>
                {previousQuestion.question.options.map((option) => (
                  <p key={option.id}>
                    {option.text}:{" "}
                    {previousQuestion.answer === option.value ? "Yes" : "No"}
                  </p>
                ))}
              </div>
            )}
            {previousQuestion.question.type === "text" && (
              <p>{previousQuestion.answer}</p>
            )}
          </div>
        ))}
      </div>
      {showSuccessMessage ? (
        <div className="success-message">Thank you for submitting your answers!</div>
      ) : (
        <div className="question-container">
          <h2>{currentQuestion.text}</h2>
          {currentQuestion.type === "text" && (
            <input
              type="text"
              value={answers[currentQuestion.id]}
              onChange={handleAnswerChange}
              key={currentQuestion.id}
            />
          )}
          {currentQuestion.type === "radio" && (
            <div>
              {currentQuestion.options.map((option) => (
                <label key={option.id}>
                  <input
                    type="radio"
                    value={option.value}
                    checked={answers[currentQuestion.id] === option.value}
                    onChange={handleAnswerChange}
                  />
                  {option.text}
                </label>
              ))}
            </div>
          )}
          <button classname="nextBtn" onClick={handleNextClick}>
            {currentQuestion.nextQuestion ? "Next" : "Submit"}
          </button>
        </div>
      )}
    </div>
  );
};

export default DynamicForm;
