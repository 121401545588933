import React, { useEffect, useState } from 'react';
import { FaMicrophone, FaStop, FaCheck } from 'react-icons/fa';
import { ReactMic } from 'react-mic';
import '../components/mic.scss';
import useClipboard from 'react-use-clipboard';
import SpeechRecognition,{ useSpeechRecognition} from 'react-speech-recognition';

import WaveBars from './waves/waves';
import Formdy from '../pages/Formdy/Formdy';

const Mic = (props)=> {
  const [recording, setRecording] = useState(false);
  const [audioURL, setAudioURL] = useState(null);
  const [textCopy,setTextCopy] = useState();
  const [shouldClearTranscript, setShouldClearTranscript] = useState(false);
  const [isCopied, setCopied] = useClipboard(textCopy);
  const [transcriptValue, setTranscriptValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  

  const { transcript,resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition(); 

  useEffect(() => {
    if (transcript !== null && transcript !== undefined) {
      setTextCopy(transcript);
      setTranscriptValue(transcript);
     
    }
  }, [transcript]);

  if(!browserSupportsSpeechRecognition){
    return null;
  }



  const handleClearTranscript = () => {
   
    
    if(transcript){
      resetTranscript();  
    }
  };

  const sendMyData = () =>{
    console.log(transcriptValue);
    props.parentCallback(transcriptValue);
  }

  const handleStartRecording = () => {
    setRecording(true);
    SpeechRecognition.startListening({continuous:true, language: 'en-IN'})

  };

  const handleStopRecording = async (recordedBlob) => {
    setRecording(false);
    setAudioURL(URL.createObjectURL(recordedBlob.blob));
    SpeechRecognition.stopListening()
  };
  

  return (
    <div className='MicReco'>
    <div className="mic">
      {!recording ? (
        <button  className ="microp" onClick={handleStartRecording}>
        <FaMicrophone />
        </button>   
      ) : (
        <button onClick={() => {setRecording(false); sendMyData();}}>
          <FaStop />
        </button>
      )}
      {recording && (
        <WaveBars />
      )}
      {
        audioURL ? <FaCheck className='Tickcon' />:""
      }
      
      <div className='micAWaves'>
        <ReactMic
          record={recording}
          visualSetting={false}
          onStop={handleStopRecording}
          mimeType="audio/mp3"
        />
        {/* {audioURL && (
          <div className="audio-player">
            <audio controls src={audioURL} />
          </div>
        )} */}
     
      
      </div>
      {isLoading && <div className="loading">Loading...</div>}
      
     
    </div>
    <div className='Mycontainer'>
      
         {shouldClearTranscript ? (
          <p></p>
        ) : (
          <p>{transcript}</p>
         
        )}
       
        
        </div>
       <div className='TransOp'>
       
          <button className="copyBtn" onClick={setCopied}>{isCopied?"Copy text":"Copy text"}</button>
          <button className='clrBtn' onClick={handleClearTranscript}>Clear text</button>
         
          
          </div>
           </div>
  );
}

export default Mic;
