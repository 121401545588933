import { useState } from "react";
import TextareaAutosize from 'react-textarea-autosize';

const AutoGrowingInput = ({ value, onInputChange }) => {
    const handleChang = (event) => {
        const newValue = event.target.value;
        onInputChange(newValue);
      };
  
    return (
      <TextareaAutosize
        value={value}
        onChange={handleChang}
        minRows={2}
        maxRows={8}
        style={{ width: '100%',
        paddingLeft:'0.3rem',
        paddingTop:'0.3rem',
        fontSize:'1.3rem' }}
      />
    );
  };

  export default AutoGrowingInput;
  