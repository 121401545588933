import { useEffect, useState } from 'react';
import './keyboardresponse.scss';
import "../App.scss";
import Lottie from 'lottie-react';
import callmy from '../assets/askCall.json';
import PhoneInput from 'react-phone-input-2';
import loadOtp from '../assets/loadOtp.json';
import Mysuccess from '../assets/mysuccess.json';
import { toast } from 'react-toastify';
import "react-phone-input-2/lib/bootstrap.css"
import { FiFlag } from 'react-icons/fi';
import { CountryDropdown} from 'react-country-region-selector';

import axios from 'axios';
// import PhoneInput from 'react-phone-number-input';


const MyKeyboard = ({ closeKeyresponse, isKeyfirstres, selectedOption}) =>{
    useEffect(()=>{
        document.body.style.overflowY = "hidden";
        return ()=>{
            document.body.style.overflowY = "scroll";
        };

    },[]);

    let verficationCode ="";
    const[confir,setConfirm] = useState(true);  // for disappearing of phone no. ui
    // const [phone_no,setphone_no] = useState("");  // for number
    // const [value, setValue] = useState("");  //for country code
    const [CallStatus,setCallStatus] = useState(""); //for checking status
    const [selectedCountry, setSelectedCountry] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [storedPhoneNumber, setStoredPhoneNumber] = useState('');
    const [data, setData] = useState({ phone_no: "" });
  

    const handlePhoneChange = (value) => {
      setPhoneNumber(value);
    };

    useEffect(() => {
      setData({ phone_no: `+${phoneNumber}` });
    }, [phoneNumber]);
     

    
  // const HandleSavePhoneNumber = () => {
  //   setData({ phone_no: phoneNumber });
    
  //   // console.log(storedPhoneNumber);
  // };
   
  // useEffect(() => {
  //   console.log(storedPhoneNumber);
  // }, [storedPhoneNumber]);
  

    const [verifiedOtp,setVerifiedOtp] = useState("");
   
   

    const diffToast = () =>{
      toast.success("Successful request",{
        position:"top-center",
        
        style:{
          backgroundColor:'black',
          color:'white',
          height:10,
          Width:240,
        marginLeft:30,
        marginRight:30,
        marginTop:10,
        marginBottom:20,
        borderRadius:15,
        paddingTop:10,
        paddingBottom:10,

      
        }

      });
    }

    const phoneNumbers = async () =>{

    
       
  
  };



  useEffect(() => {
    // console.log(verifiedOtp);
  }, [verifiedOtp]);
   

  useEffect(()=>{
    // console.log(CallStatus);
  },[CallStatus])
   
   

   
 
  
    
    // const ConfirmVisibility = () => {
    //  setTimeout(async() => {
    //   if(CallStatus ==="loading"){
    //     setCallStatus("verifiedUser");
    //   }
    //  }, 3000);
    //   }

      const OtpContinue = () =>{
        
      }

      // if(verifiedOtp ===""){
      //   isVerified(true);
      // } else{
      //   setIsConfirm(true);
      // }
    return (
      <>
      <div className="modal-wrapper" ></div>

      <div className="modal-container">
      <Lottie className='Phonerec' loop={2} animationData={Mysuccess}/>
        <h2 className='SuccessP'>Form Submitted Successfully</h2>
        <h3 className='myTitle'>Your Responses</h3>
        <div className='FirstResponse'>
        <h3 className='Questi'>Question asked: Please provide any comments/suggestions for the management to better meet your needs.</h3>
        <h3>Your answer:- {isKeyfirstres}</h3>
        </div>
        <div className='SecondResponse'>
        <h3>Question asked:- Rate your overall experience at Stonebridge Gardens</h3>
            <h3>Your answer:- {selectedOption}</h3>
        </div>
        
    
      




       
        </div> 
      </>
    );
  };

  export default MyKeyboard;