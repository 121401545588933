// TranscriptContext.js
import React, { createContext, useState } from 'react';

// Create the context
export const TranscriptContext = createContext();

// Create the provider
export const TranscriptProvider = ({ children }) => {
  const [transcriptValue, setTranscriptValue] = useState('');

  // Function to update the transcript value
  const updateTranscript = (newTranscript) => {
    setTranscriptValue(newTranscript);
  };

  // Provide the transcript value and update function to the child components
  return (
    <TranscriptContext.Provider value={{ transcriptValue, updateTranscript }}>
      {children}
    </TranscriptContext.Provider>
  );
};
