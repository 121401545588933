import Mic from '../../components/MicRecord';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './formy.scss';
import { FaMicrophone, FaKeyboard, FaPhoneAlt } from 'react-icons/fa';
import "../../App.scss";
import questionsData from "../../assets/questions.json";
import { useContext, useEffect, useRef, useState } from 'react';
import MyModal from '../../components/ShowModal';
import axios from 'axios';
import MyKeyboard from '../../components/Keyboardresp';
import SecondMic from '../../components/secondmic/SecondMic';
import MyMicResp from '../../components/MicResponse';
import { TranscriptContext } from '../../components/TranscriptContext';
import AutoGrowingInput from '../../components/AutosizeInp';
   



const Formdy =() => {
  const [transcriptValue, setTranscriptValue] = useState('');
    const handleCallback  = (micData) => {
      console.log(micData);
      setTranscriptValue(micData)
     }
      
     const [secondtranscript,setSecondTranscript] = useState('');
     const handleSecondMic = (secondmicData) =>{
      console.log(secondmicData);
      setSecondTranscript(secondmicData)
      
     }

    
      const diffToast = () =>{
        toast.success("Submitted Successfully",{
          position:"top-center",
          
          style:{
            backgroundColor:'black',
            color:'white',
            height:5,
          marginLeft:30,
          marginRight:30,
          marginTop:10,
          marginBottom:20,
          borderRadius:15,
          paddingTop:10,
          paddingBottom:10,

        
          }

        });
      }
    
      const errToast = () =>{
        toast.error("Submitted Unsuccessful",{
          position:"top-center"
        });
      }
      const [transcript1, setTranscript1] = useState("");
  const [transcript2, setTranscript2] = useState("");

  // Callback function to handle transcripted value for question 1
  const handleTranscriptChange1 = (newTranscript) => {
    setTranscript1(newTranscript);
  };

  // Callback function to handle transcripted value for question 2
  const handleTranscriptChange2 = (newTranscript) => {
    setTranscript2(newTranscript);
  };
    
      
      const [isVisible, setIsVisible] = useState(false);
      const [isMicOption,setIsMicOption] = useState(false);
      const [isMicAns,setIsMicAns] = useState(false);
     const[isKeyboardOption,setisKeyboardOptions] = useState(true);
     const[isKeyboardQues,setiskeyboardQues] = useState(false);
     const[isKeyfirstres,setisKeyFirstres] = useState("");
     const [showPopupkeyboard, setShowPopupKeyboard] = useState(false);
     const [showMicPop,setShowMicPop] = useState(false);
     const [isMicQue,setisMicQue] = useState(false);
     const [apiResponse, setApiResponse] = useState(null);
     const [isForm,setIsForm] = useState(false);
     
     const [storedValue, setStoredValue] = useState('');

     const handleInputChang = (value) => {
       setStoredValue(value);
     };
     const textareaRef = useRef(null);

     useEffect(() => {
       adjustTextareaHeight();
     }, [isKeyfirstres]);
   
     const adjustTextareaHeight = () => {
       if (textareaRef.current) {
         textareaRef.current.style.height = 'auto'; // Reset the height to auto
         textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Set the height to match the scrollHeight
       }
     };
   
         

  const [showModal,setShowModal] =useState(false);
  const closeModal = () => setShowModal(false);
  const closeKeyresponse = () =>setShowPopupKeyboard(false);
  

 
  const [selectedOption, setSelectedOption] = useState('');
  const [selectOpt,setSelectOpt] = useState('');
  const [keyAnswer,setKeyAnswer] = useState('');
  const [micAnswer,setMicAnswer] = useState('');

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleOpt = (event)=>{
    setSelectOpt(event.target.value);
    
  }

  useEffect(()=>{
    if(selectedOption=== 'Awesome'){
      setKeyAnswer('5');
    }
    else if(selectedOption === 'Good'){
      setKeyAnswer('4');
    }
    else if(selectedOption === 'Average'){
      setKeyAnswer('3');
    }
    else if(selectedOption === 'Bad'){
      setKeyAnswer('2');
    }
    else if(selectedOption === 'Terrible'){
      setKeyAnswer('1');
    }


  
  },[selectedOption]);


  useEffect(()=>{
    if(selectOpt=== 'Awesome'){
      setMicAnswer('5');
    }
    else if(selectOpt === 'Good'){
      setMicAnswer('4');
    }
    else if(selectOpt === 'Average'){
      setMicAnswer('3');
    }
    else if(selectOpt === 'Bad'){
      setMicAnswer('2');
    }
    else if(selectOpt === 'Terrible'){
      setMicAnswer('1');
    }


  
  },[selectOpt]);

  //For enter keybutton to keyboard  
  const textInputRef = useRef(null);

  // const handleRadioKeyDown = (event) => {
  //   if (event.key === "Enter") {
  //     handleNextClick();
  //   }
  // };

  const renderKeyboardQues = () =>{
    setisKeyboardOptions(!isKeyboardOption);
    setiskeyboardQues(!isKeyboardQues);
    
  }
  const renderMicQues = () =>{
    setisKeyboardOptions(!isKeyboardOption);
    setisMicQue(!isMicQue);
    
    
  }

  
 
  const KeyboardResponse = async () =>{
   setShowPopupKeyboard(true);
  //  console.log(keyAnswer);
    try {
      const responses = await axios.post(
        "https://api.albertbi.com/logs/store/by_medium",
         {
          medium_type: "keyboard",
          ans_1 : storedValue,
          rating: keyAnswer,
         }
      );

      // setApiResponse(responses);
      // console.log(responses.data.Item);
   
    } catch (error) {
     console.log(error);
    }

   

};

const MicResponse = async() =>{
 setShowMicPop(true);
 try {
  const responses = await axios.post(
    "https://api.albertbi.com/logs/store/by_medium",
     {
      medium_type: "mic",
      ans_1 : transcriptValue,
      rating: micAnswer,
     }
  );

  // setApiResponse(responses);
  // console.log(responses.data.Item);

} catch (error) {
 console.log(error);
}

};

const Formdis = () =>{
  setIsForm(true);
}

 
    
      const toggleVisibility = () => {
        setIsVisible(!isVisible )
        setIsMicOption(false);
      }
    
      const MicVisibility = () =>{
        setIsMicAns(false);
        setIsMicOption( !isMicOption);
      }
      const MictwoVisibiltiy = () =>{
        setIsMicOption(false);
        setIsMicAns(true);
      }
  return (
    <div className='Question'>
    <div className='row1'>
   
     <h2 className='SecondTopic'>User Experience Survey</h2>
     <p className='Quesdesc'>This survery is to help the Stone Bridge team to 
understand how our user see our reviews data</p>
    </div>
    <div className='spaced'>

    </div>
    <div className='row2'>
      { isKeyboardOption &&
     <div className='question-default'>
      <h2>Select how do you want to answer the survey?</h2>
      <div className='TwoOptions'>
        <div className='showOption'>
        <button className="TextShow" onClick={renderKeyboardQues}>   <FaKeyboard className='keyb'/>  Keyboard</button>
        <h3 className='give'>  OR  </h3>
        <button className="TextShow"  onClick={renderMicQues}><FaMicrophone className='micb ' /> Mic</button>
       <h3 className='give'>  OR  </h3>
       
       <button className="TextShow" onClick={()=> setShowModal(true)}><FaPhoneAlt className='callb'/> Call</button>
       <>
       {showModal && <MyModal closeModal ={closeModal}/>}
       </>
       </div>
        </div>
     </div> 
     }
     {  isKeyboardQues &&
          <>
            <h3 className='FadedAns'>(You have selected Keyboard to answer the survery)</h3>
            <div className='keyboardQues'>
            <div className='FirstQues'>
            <h2>Please provide any comments/suggestions for the management to better meet your needs.</h2>
              {/* <input type='text' className='keyboardInput'   value={isKeyfirstres} onChange={(e)=>{setisKeyFirstres(e.target.value)}}></input> */}
              <AutoGrowingInput value={storedValue} onInputChange={handleInputChang} />

              
            </div>
            <div className='SecondQues'>
            <h2> Rate your overall experience at Stonebridge Gardens</h2>
            <label className='answ'>
        <input
          type="radio"
          value="Awesome"
          checked={selectedOption === 'Awesome'}
          onChange={handleOptionChange}
        />
       Awesome
      </label>
      <label className='answ'>
        <input
          type="radio"
          value="Good"
          checked={selectedOption === 'Good'}
          onChange={handleOptionChange}
        />
       Good
      </label>
      <label className='answ'>
        <input
          type="radio"
          value="Average"
          checked={selectedOption === 'Average'}
          onChange={handleOptionChange}
        />
        Average
      </label>
      <label className='answ'>
        <input
          type="radio"
          value="Bad"
          checked={selectedOption === 'Bad'}
          onChange={handleOptionChange}
        />
       Bad
      </label>
      <label className='answ'>
        <input
          type="radio"
          value="Terrible"
          checked={selectedOption === 'Terrible'}
          onChange={handleOptionChange}
        />
     Terrible
      </label>
     
            </div>
          <button className='nextBtn' onClick={()=>{ KeyboardResponse(); Formdis();}} >Submit</button>
          {showPopupkeyboard && <MyKeyboard closeKeyresponse ={closeKeyresponse}
             isKeyfirstres={storedValue}
    selectedOption={selectedOption}
          />}
            </div>
          </>
        }

        {
          isForm &&
          <>
            <h3>Form is submitted successfully</h3>
          </>
        }
       
    


        {  isMicQue &&
          <>
            <h3 className='FadedAns'>(You have selected microphone to answer the survery)</h3>
            <div className='micQues'>
            <div className='FirstQues'>
            <h2> Please provide any comments/suggestions for the management to better meet your needs.</h2>
            <Mic parentCallback = {handleCallback}/>
            </div>
            <div className='SecondQ'>
            <h2> Rate your overall experience at Stonebridge Gardens (Please rate on a scale of 1 to 5, with 5 being the best.)</h2>
            {/* <SecondMic secondParentCallback = {handleSecondMic}/> */}
            <label className='answ'>
        <input
          type="radio"
          value="Awesome"
          checked={selectOpt === 'Awesome'}
          onChange={handleOpt}
        />
        Awesome
      </label>
      <label className='answ'>
        <input
          type="radio"
          value="Good"
          checked={selectOpt === 'Good'}
          onChange={handleOpt}
        />
        Good
      </label>
      <label className='answ'>
        <input
          type="radio"
          value="Average"
          checked={selectOpt === 'Average'}
          onChange={handleOpt}
        />
        Average
      </label>
      <label className='answ'>
        <input
          type="radio"
          value="Bad"
          checked={selectOpt === 'Bad'}
          onChange={handleOpt}
        />
       Bad
      </label>
      <label className='answ'>
        <input
          type="radio"
          value="Terrible"
          checked={selectOpt === 'Terrible'}
          onChange={handleOpt}
        />
       Terrible
      </label>
            
           </div>
           <button className='nextBtn' onClick={MicResponse} >Submit</button>
           {
            showMicPop && <MyMicResp  selectedOption={selectOpt} transcriptValue={transcriptValue}/>}
           
            </div>
          </>
        }
    
   </div>
  </div>
  )
}

export default Formdy