import logo from './logo.svg';
import './App.scss';
import Ques from './pages/Ques/Ques';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,

} from "react-router-dom";
import DynamicForm from './pages/Feeds/Feeds';
import Formdy from './pages/Formdy/Formdy';
import { TranscriptProvider } from './components/TranscriptContext';


const App =() => {
  return (
  <Router>
  <TranscriptProvider>
  <Routes>
    <Route exact path= "/" element ={<Formdy/> } />
    <Route exact path='/feed' element ={<DynamicForm/>}/>
    <Route exact path='/ques' element = {<Ques/>}/>
  </Routes>
  </TranscriptProvider>
  </Router>
  );
}

export default App;
