import { useEffect, useState } from 'react';
import './showModal.scss';
import "../App.scss";
import Lottie from 'lottie-react';
import callmy from '../assets/askCall.json';
import PhoneInput from 'react-phone-input-2';
import loadOtp from '../assets/loadOtp.json';
import Mysuccess from '../assets/mysuccess.json';
import { toast } from 'react-toastify';
import "react-phone-input-2/lib/bootstrap.css"
import { FiFlag } from 'react-icons/fi';
import { CountryDropdown} from 'react-country-region-selector';

import axios from 'axios';
// import PhoneInput from 'react-phone-number-input';


const MyModal = ({ closeModal}) =>{
    useEffect(()=>{
        document.body.style.overflowY = "hidden";
        return ()=>{
            document.body.style.overflowY = "scroll";
        };

    },[]);

    let verficationCode ="";
    const[confir,setConfirm] = useState(true);  // for disappearing of phone no. ui
    // const [phone_no,setphone_no] = useState("");  // for number
    // const [value, setValue] = useState("");  //for country code
    const [CallStatus,setCallStatus] = useState(""); //for checking status
    const [selectedCountry, setSelectedCountry] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [storedPhoneNumber, setStoredPhoneNumber] = useState('');
    const [data, setData] = useState({ phone_no: "" });
    const [apiResponse, setApiResponse] = useState("");
    const [rating,setRating] = useState("");

  

    const handlePhoneChange = (value) => {
      setPhoneNumber(value);
    };

    useEffect(() => {
      setData({ phone_no: `+${phoneNumber}` });
    }, [phoneNumber]);
     

    
  // const HandleSavePhoneNumber = () => {
  //   setData({ phone_no: phoneNumber });
    
  //   // console.log(storedPhoneNumber);
  // };
   
  // useEffect(() => {
  //   console.log(storedPhoneNumber);
  // }, [storedPhoneNumber]);
  

    const [verifiedOtp,setVerifiedOtp] = useState("");
   
   

    const diffToast = () =>{
      toast.success("Successful request",{
        position:"top-center",
        
        style:{
          backgroundColor:'black',
          color:'white',
          height:10,
          Width:240,
        marginLeft:30,
        marginRight:30,
        marginTop:10,
        marginBottom:20,
        borderRadius:15,
        paddingTop:10,
        paddingBottom:10,

      
        }

      });
    }

    const phoneNumbers = async () =>{

        // console.log(data);
      setConfirm(false);
      setCallStatus("loading");


        try {
          const responses = await axios.post(
            "https://api.albertbi.com/call/invoke-call",
            data
          );
          // console.log("Checkpoint 3");
          // console.log(responses == "")
          // console.log(responses.data);
          // console.log("Checkpoint 4");
          if (responses.data.validation_code !=="") {
            setVerifiedOtp(responses.data.validation_code);
            setCallStatus("otpReceive");
            
            // console.log(responses.data.validation_code);
          } 
        else if(responses.data.validation_code ===""){
           setCallStatus("verifiedUser");
          }
        } catch (error) {
          setCallStatus("verifiedUser");
        }
    
       
  
  };



  useEffect(() => {
    // console.log(verifiedOtp);
  }, [verifiedOtp]);
   

  useEffect(()=>{
    // console.log(CallStatus);
  },[CallStatus])
   
    const TranscriptResult = async()=>{
      setCallStatus("loading");
      const transResponse = await  axios.post(
        "https://api.albertbi.com/logs/v2/by_phone",
        data
      );
      console.log(transResponse.data);
      console.log(transResponse.data.data.transcription_text);
      console.log(transResponse.data.data.rating);
      if(transResponse !==""){
     setApiResponse(transResponse.data.data.transcription_text);
     setRating(transResponse.data.data.rating);
     setCallStatus("myResponse");  
    }
     
    }
    
    

   
 
  
    
    // const ConfirmVisibility = () => {
    //  setTimeout(async() => {
    //   if(CallStatus ==="loading"){
    //     setCallStatus("verifiedUser");
    //   }
    //  }, 3000);
    //   }

      const OtpContinue = () =>{
        
      }

      // if(verifiedOtp ===""){
      //   isVerified(true);
      // } else{
      //   setIsConfirm(true);
      // }
    return (
      <>
      <div className="modal-wrapper" ></div>

      <div className="modal-container">
      {
        confir&&
      <>
        <h2>Enter your phone number</h2>
        
        
        {/* {selectedCountry && (
          <FiFlag className="country-flag" />
        )} */}
      
      <PhoneInput
       className="PhoneInput"
     international
        countryCallingCodeEditable={false}
        defaultCountry={selectedCountry}
        flagUrl=''
        value={phoneNumber}
        onChange={handlePhoneChange}
        inputProps={{
          style:{
           
   
      minWidth: '14rem',
      ...(window.innerWidth >375 && { maxWidth: '4rem'}),
      
    }
    
       // Set the desired width here
    }}
      />
        <button onClick={()=>{ diffToast(); phoneNumbers();}} className='modal-btn'>Continue</button>
        </>
      }
      {
        CallStatus === "loading" &&
        <>
        <Lottie className='Phonerec' loop={true} animationData={loadOtp}/>
        <h2 className='otpState'>Please wait</h2>
       
        </>
      }

         {
           CallStatus === "verifiedUser" &&
            <>
            <h2 className='otpState'>This number is verified. You will receive a call shortly</h2>
            
            <Lottie className='Phonerec' loop={true} animationData={callmy}/>
            <h3 className='phState'>(Only after completing survey,click see my response below)</h3>
            <button onClick={()=>{  TranscriptResult();}} className='receBtn'>See my response</button>
           
        </>

         }

         {
          CallStatus === "myResponse" && 
          <>
          <Lottie className='Phonerec' loop={2} animationData={Mysuccess}/>
          <h2 className='SuccessP'>Form Submitted Successfully</h2>
          <h3 className='myTitle'>Your Responses</h3>
        <div className='FirstResponse'>
        <h3 className='Questi'>Question asked:- Please provide any comments/suggestions for the management to better meet your needs.</h3>
        <h3>Your answer:- {apiResponse}</h3>
        </div>
        <div className='SecondResponse'>
        <h3 className='Questi'>Question asked:- Rate your overall experience at Stonebridge Gardens</h3>
        <h3>Your answer:- {rating}</h3>
        </div>
          </>
         }



         {
          CallStatus === "otpReceive" &&
         <>  
           <h2 className='otpState'>You will receive a voice call to verify your mobile number. Enter the below OTP when you get the call</h2>
        <div className='otp-here'>
        <input type='text' className='otp-input'  value={verifiedOtp} ></input>
        <h3 className='contState'>Only after entering OTP on the phone call, click Continue below</h3>
        <button onClick={()=>{OtpContinue(); diffToast(); phoneNumbers();}} className='my-otp'>Continue</button>
        </div> 
           </>
         }
        </div> 
      </>
    );
  };

  export default MyModal;